import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import Footer from '../components/Footer';
import SEO from '../components/seo';
import '../styles/app.scss';

const WeRecommend = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    console.log(post.yoast_json_ld[0].wordpress__graph[0].description);

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[1].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <section className="page-section centersec smallestwdt nopaddbottsec">
                <div className="container">
                    <h2
                        className="bluetxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.we_recommend_heading,
                        }}
                    />
                    <p
                        className="lastitem biggertxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.we_recommend_description,
                        }}
                    />

                    <img
                        className="schoolsline"
                        src={LineFull}
                        alt="linefull"
                    />
                </div>

                <div className="werecommwrap">
                    {post.acf.we_recommend_logos.map((logo, i) => (
                        <div className="recommimg" key={i}>
                            <a
                                href={logo.wecommend_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={
                                        logo.recommend_logo.localFile
                                            .childImageSharp.fluid.src
                                    }
                                    alt="werec"
                                />
                            </a>
                        </div>
                    ))}
					
					<div className="werecommwrap newrecom">
					  <div className="recominfo">
					  <div className="recommimg"> 
                          <img src="https://werockthespectrumasheville.wrtsfranchise.com/wp-content/uploads/2020/08/Biltmore-Upholstery.jpg" />
					 </div>
					 <p>828-676-2400</p>
					 </div>
					 
					 <div className="recominfo">
					  <div className="recommimg"> 
                        <a href="https://www.brittandtilson.com/" target="_blank"><img src="https://werockthespectrumasheville.wrtsfranchise.com/wp-content/uploads/2020/08/britt-tilson-glass.png" /></a>
					 </div>
					 <p>828-253-6706</p>
					 </div>
					
					
					 <div className="recominfo">
					  <div className="recommimg"> 
                        <a href="http://crossroadsupholstery.com/" target="_blank"><img src="https://werockthespectrumasheville.wrtsfranchise.com/wp-content/uploads/2020/08/crossroads-upholstery.png" /></a>
					 </div>
					 <p>828-337-9007</p>
					 </div>
					 
					 <div className="recominfo">
					  <div className="recommimg"> 
                        <img src="https://werockthespectrumasheville.wrtsfranchise.com/wp-content/uploads/2020/08/Dianne-Ross-Therapy.png" />
					 </div>
					 <p>828-337-9007</p>
					 </div>
					 
					 <div className="recominfo">
					  <div className="recommimg"> 
                        <a href="https://www.covestar.com/" target="_blank"><img src="https://werockthespectrumasheville.wrtsfranchise.com/wp-content/uploads/2020/08/covestar.png" /></a>
					 </div>
					 <p>Jessica Auge, Real Estate Broker</p>
					 <p><a href="mailto:brokerjessauge@gmail.com">brokerjessauge@gmail.com</a></p>
					 </div>
					 
					 {/* <div className="recominfo">
					  <div className="recommimg"> 
                        <a href="https://mosssigncompany.com/" target="_blank"><img src="https://werockthespectrumasheville.wrtsfranchise.com/wp-content/uploads/2020/08/moss-sign-company.png" /></a>
					 </div>
					 </div> */}
					 
					 <div className="recominfo">
					  <div className="recommimg"> 
                        <a href="https://www.rhythminteriorproducts.com/" target="_blank"><img src="https://werockthespectrumasheville.wrtsfranchise.com/wp-content/uploads/2020/08/Rhythm-Interiors.png" /></a>
					 </div>
					 <p>Claire Wiese, Interior Project Manager</p><p>206-476-5248</p>
					 <p><a href="www.facebook.com/ASHEbuilt">www.facebook.com/ASHEbuilt</a></p>
					 </div>
					 
					 <div className="recominfo">
					  <div className="recommimg"> 
                        <img src="https://werockthespectrumasheville.wrtsfranchise.com/wp-content/uploads/2020/08/Sean-@-Superior-Welding_03-1.jpg" />
					 </div>
					 <p>R. Sean Coffey, Project Manager</p><p>828-680-1301</p>
					 </div>
					 
                     <div className="recominfo">
					  <div className="recommimg"> 
                      <a href="https://matonecounseling.com/" target="_blank"> <img src="https://werockthespectrumasheville.wrtsfranchise.com/wp-content/uploads/2022/02/Matone-Logo_250x111.png" /></a>
					 </div>
					 <p>828-333-9320</p>
					 </div>
                     <div className="recominfo">
					  <div className="recommimg"> 
                      <a href="https://www.keyautismservices.com/autism-aba-therapy-in-the-asheville-autism-therapy-center/?gclid=Cj0KCQiA0eOPBhCGARIsAFIwTs6K9JmpdZVQUbXn5bza29M6kJE-3NQT0cNhhr-36OlnM7uiqZMV4ykaAh_vEALw_wcB" target="_blank"> <img src="https://werockthespectrumasheville.wrtsfranchise.com/wp-content/uploads/2022/02/Signature-Logo-ONLY-03.png" /></a>
					 </div>
					 <p></p>
					 </div>
					  {/* <div className="recominfo">
					  <div className="recommimg"> 
                        <img src="https://werockthespectrumasheville.wrtsfranchise.com/wp-content/uploads/2020/09/Elite-Gymnastics.jpg" />
					 </div>
					 <p><a href="https://www.facebook.com/EliteGymSupply/" target="_blank">www.facebook.com/EliteGymSupply/</a></p>
					 </div> */}
					 
					</div>
					
                </div>
            </section>
            <Footer />
        </>
    );
};

export default WeRecommend;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
            acf {
                we_recommend_heading
                we_recommend_description
                we_recommend_logos {
                    recommend_logo {
                        localFile {
                            childImageSharp {
                                fluid {
                                    srcWebp
                                    src
                                }
                            }
                        }
                    }
                    wecommend_url
                }
            }
        }
    }
`;
